import React from 'react';

import MainLayout from '../layouts/MainLayout';

const IndexPage = () => {
  return (
    <MainLayout>
      <div>
        <aside className='relative overflow-hidden text-black rounded-lg sm:mx-16 mx-2 sm:py-16'>
          <div className='relative z-10 max-w-screen-xl px-4  pb-20 pt-10 sm:py-24 mx-auto sm:px-6 lg:px-8'>
            <div className='max-w-xl sm:mt-1 mt-80 space-y-8 text-center sm:text-right sm:ml-auto'>
              <h2 className='text-4xl font-bold sm:text-5xl'>
                Tool App
                <span className='hidden sm:block text-4xl'>Save your Time</span>
              </h2>
            </div>
          </div>

          <div className='absolute inset-0 w-full sm:my-20 sm:pt-1 pt-12 h-full '>
            <img className='w-96' src='https://i.ibb.co/5BCcDYB/Remote2.png' />
          </div>
        </aside>

        <div className='grid  place-items-center sm:mt-20'>
          <img className='sm:w-96 w-48' src='https://i.ibb.co/2M7rtLk/Remote1.png' />
        </div>
      </div>
    </MainLayout>
  );
};

export default IndexPage;
